import React from "react"

import Layout from "../../components/Layout"
import { Link } from "gatsby"


export default function Home() {
  return (
    <Layout breadcrumbs={["Ricardo Zylbergeld"]}>
      <div id="heading1" >
        <h1>Ricardo Zylbergeld</h1>
      </div>

      {/* Main */}
      <section id="main" className="wrapper">
        <div className="inner">

          <div className="content page">

            {<h5><Link to="/">Inicio</Link> &gt;&gt; Equipe  &gt;&gt; Ricardo Zylbergeld</h5>}

            <header>
              <h2>Cofundador</h2>
            </header>

            <div style={{ display: 'flex' }}>
              <div>
                <p>Sou um desenvolvedor sênior apaixonado por tecnologias que promovam impacto
                social. Nos últimos anos, venho construindo soluções para colaboração
                comunitária, proteção do meio ambiente e para projetos que envolvam novos
                arranjos econômicos. Me envolvi, também, em projetos de apoio à gestão de ONGs e
                de computação física para eventos. Anteriormente, fui sócio em um site de
                relacionamento.
                </p>
                <p>Atualmente, estou consolidando um novo empreendimento chamado
                Engajados, direcionado à ressignificação do papel da tecnologia no estímulo à
                emergência de inteligência comunitária.
                </p>
                {/* <ul>
                  <li>Acesso ao projeto (versão beta) em: <a href="http://monitoraea.org.br/" target="_blank">www.monitoraea.org.br</a></li>
                </ul> */}
              </div>
              {/* <div>
                <span className="image special"><img src={lab1} alt="" /></span>
                <span className="image special"><img src={lab2} alt="" /></span>
                <span className="image special"><img src={lab1} alt="" /></span>
              </div> */}
            </div>

          </div>
        </div>
      </section>
    </Layout>
  )
}
